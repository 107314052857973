import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
import Video from "../../components/Video";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "493px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f15dc12b8b9dcdf601ad698e19c95aad/f88d0/dq1-title.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.61003861003861%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABFElEQVQY01VQW26DMBD0HdMeoPQn6QVC1UQtttdA1CBFQuEVVPEDSkCgCLhfxzih6Wo0mtkdGy9s+/a8WS4+lovNauIZxq6e5tHW6HsYlhEpKSRJJUGPEER3TTeY5G0qhGSu63LOHceRUkKAxVSzxYhzE/nLqKnY4XCoqqqu69Pp1DRNnudFUZRlmaYpOMsyjM7nM3Tbtsfj8XK5JEni+z4RMXh0+76PoqjrumEYcNfPVOYuHIbA+XEc4zi+Xq9hGOJp+sueqwTe9PVJ+sHc91wsJCegjUUxQx9GcAc/BlZvrwhg0t1x8oTyufLBjvRgoTlpa1ionZnqJGk2YMH769629mtLs20F9k3M9ts01y/B/wDEL++E++o5ONsJAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Dragon Quest Title",
            "title": "Dragon Quest Title",
            "src": "/static/f15dc12b8b9dcdf601ad698e19c95aad/f88d0/dq1-title.png",
            "srcSet": ["/static/f15dc12b8b9dcdf601ad698e19c95aad/a2ead/dq1-title.png 259w", "/static/f15dc12b8b9dcdf601ad698e19c95aad/f88d0/dq1-title.png 493w"],
            "sizes": "(max-width: 493px) 100vw, 493px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This is the second article in my Dragon Quest retrospective series. As previously mentioned I'm going to go out of order and do them in whatever order feels natural.  `}</p>
    <p><strong parentName="p">{`Other articles in the series:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../DragonQuest4"
        }}>{`Dragon Quest 4`}</a></li>
    </ul>
    <p>{`I'll start by saying this is the very first RPG I ever played. I got it because there was a cross promotional thing with Nintendo Power where they sent you the strategy guide and the game. I didn't know what to expect going in, but boy was I hooked.`}</p>
    <h2>{`Story`}</h2>
    <p>{`There is very little main story in this game. You start the game in the thrown room, talking to the king, who tells you the Dragonlord stole the Sphere of Light and if you don't stop him and get it back the world will be plunged into eternal darkness. That's it. In a way its an open world RPG akin to classic Might and Magic. Outside of your overall quest, you're given no other specifics. You just talk to the townspeople to figure out where things are and who is important, and your only true barrier to progress anywhere is your level and equipment.`}</p>
    <p>{`You do find out that the princess was also abducted. However, finding and rescuing her this is 100% optional. Eventually some things get pieced together like how you need the Rainbow Drop to create a bridge to the Dragonlords castle, or how you can find a harp to put the Golem guarding Cantlin to sleep so he doesn't outright crush you. There is however one truly interesting decision you get to make. When you first encounter the Dragonlord he makes you a deal. Stand down and rule half the world with him, or fight him and die. If you choose to join him, the game ends and you get an ending of sorts. Of course the happy ending comes from defeating him, where you are given the kingdom and marry the princess, assuming you saved her.`}</p>
    <Video videoSrcUrl='https://www.youtube.com/embed/c-u-bq-CXz4?t=53' videoTitle='Offered a choice' mdxType="Video" />
    <p>{`All in all, your very first time through this game will probably take around 20-25 hours mostly because you do have to figure it all out on your own. Subsequent playthroughs can easily be done within a handful of hours, or if you're a speed runner manipulating the RNG, under 30 minutes.`}</p>
    <h2>{`Combat`}</h2>
    <p>{`As there is only a single party member. Combat is very straightforward. You can either attack or use one of a handful of spells you learn along the way. In general, at least in the old NES version, it's far better to just attack and save those precious MP for Healmore.`}</p>
    <p>{`Here's what's great about the combat: the art. If you like Akira Toriyama at all, you'll love the monster sprites. What's incredible is that most of them have stood the test of time, receiving only minor refinements as the series went along, receiving the biggest updates when the series finally jumped to 3D. The slimes, the skeletons, the mages, the golems and the dragons have all been mainstays of the series with their core depictions laid down here. Now, here's what's bad about the combat: the music. By far the worst battle theme in the series, it's grating, it's loud, and it's bad. This leads us quite nicely into...`}</p>
    <h2>{`Music`}</h2>
    <p>{`The music in this game, with three exceptions, has not aged well. I would mute my television every time a battle started, because that theme is just... But let's talk about the good. The overworld theme is amazing, and it made it into the next two games in the series along with the Dragon Quest Builders series games too. The Dragonlord battle theme is also really good. `}</p>
    <p>{`Now let's talk about what's forever; the Dragon Quest Overture. Featured in every single game in the series since, this is a magnificent piece of music, even in it's first incarnation. Two other pieces of music should also be instantly recognizable by anyone who's played any Dragon Quest game: the level up jingle and Friendship. The former has played every time a character has leveled up in a DQ game for the last 35 years, and the latter generally plays whenever a new character joins your party.`}</p>
    <Video videoSrcUrl='https://www.youtube.com/embed/jLUDMrmjgjQ' videoTitle='DQ Overture' mdxType="Video" />
    <h2>{`Lasting Impact`}</h2>
    <p>{`Despite not aging well, so much of what Dragon Quest is, came from this game:`}</p>
    <ul>
      <li parentName="ul">{`The slime, the series mascot`}</li>
      <li parentName="ul">{`The rest Akira Toriyama's iconic monsters`}</li>
      <li parentName="ul">{`The Dragon Quest Overture, and the handful of other classics`}</li>
      <li parentName="ul">{`The battle system, though upgraded a bit, is much the same in DQ XI as it is here`}</li>
    </ul>
    <h2>{`Remake`}</h2>
    <p>{`Once again, I'll briefly touch on this topic as this game has received a few remakes over the years, the most recent being the iOS port. In all cases, the ports have polished down the rough edges of the game. Interacting with doors, chests, and characters is more straightforward, leveling up no longer bases your stat increases on your characters name, etc. If you can, I recommend playing one of the remakes.`}</p>
    <h2>{`Does It Hold Up?`}</h2>
    <p>{`In short, no. There's just not enough meat here. Single player battles and very few story beats just can't captivate like they did 35 plus years ago. It's always nice to come back for a 5 hour romp through the beginnings of the series, but your time is better spent playing any of the sequels.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      